import * as React from "react";
import Obfuscate from 'react-obfuscate';
import { Header, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const ContactPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="info circle" />
          <Header.Content>
            Contact
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <Header>
        Pierre Leclerc
        </Header>
        Corporate Secretary and Spectrum Coordinator <br/>
        PTC-220, LLC<br/>
        <Obfuscate email="Pierre.Leclerc@PTC220.org"/> <br/>
        C : <Obfuscate tel="438.342.3901"/>
      </Segment>
    </Container>
  );
};

export default withLayout(ContactPage);
